import React from "react";
import './App.css';
import NavBarRoute from "./navbar/navbarroute.js";

import Home from "./Home/Home.js";
import Blog from "./Blog/blog.js";
import MyJourney from "./myjourney/myjourney.js";
import AboutMe from "./Aboutme/aboutme.js";
import MyProjects from "./project/project.js";
import ContactPage from "./contact/contact.js";
import Footer from "./footer/footer.js";
import Skills from "./skills/skills.js";

const App = () => {
  return (
    <div className="App">
    <React.StrictMode>
      <NavBarRoute />
      <Home />
      <AboutMe/>
      <MyJourney/>
      <Skills/>
      <MyProjects/>

      <Blog/>
      <ContactPage/>
      <Footer/>
    </React.StrictMode>
    </div>
  );
};

export default App;
