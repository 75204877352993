import React, { useState } from 'react';
import './project.css';



const MyProjects = () => {
  const projects = [
    {
      id: 1,
      title: 'Linear Math Word Problem Solver',
      description: 'Model with problem solving capabilities implemented using RL and T5 algorithm. (Pandas, Django, NLP, Matplotlib, Numpy)',
      githubLink: 'https://github.com/sammanamgain/Equation_generator_and_solver_using_t5.git'
    },
    {
      id: 2,
      title: 'Visual Learner Django App',
      description: 'Integrated a Computer Vision model, serving as a practical tool to associate words with image. (Django and the integration of AI in web applications) ',
      githubLink: 'https://github.com/prinsajoshi/Python_Code_Rush_Hands_On_Sessions/tree/main/Projects/identify_image'
    },
    {
      id: 3,
      title: 'Menstrual Period Prediction Model',
      description: 'Model that predicts menstrual cycle, patterns. Milestones include Dataset Preparations, UI Design, firebase Integration and ARIMA Model Implementation',
      githubLink: 'https://github.com/sammanamgain/Menstrual-cycle-predictor-with-using-arima-model-and-flutter.git'
    },
    {
      id: 4,
      title: 'Blog Post',
      description: 'Post system where user can perform CRUD application and create a/c(Django,HTML).User can register, login and post edit,delete. User also can change the account information.',
      githubLink: 'https://github.com/prinsajoshi/Python_Code_Rush_Hands_On_Sessions/tree/main/Projects/post'
    },
    {
      id: 5,
      title: 'Calender Email Sysem',
      description: 'Email a user on required date and time (smtp,django).User can set email and time asynchronously the email is sent to the user on exact time',
      githubLink: 'https://github.com/prinsajoshi/Python_Code_Rush_Hands_On_Sessions/tree/main/Projects/event'
    },
    {
      id: 6,
      title: 'E-Pustakalaya',
      description: 'E-Pustakalaya is a django based website with an Admin Dashboard for managing authors, books, and categories. It also handles book issuance to students with return date notifications, search, and password management',
      githubLink: 'https://github.com/pratima-dawadi/E-Pustakalaya.git'
    }
  ];

  
  return (
    <div id="projects">
    <div className="projects-container">
<div className="about-container">
    
        <span className="text-animate">P</span>
        <span className="text-animate">r</span>
        <span className="text-animate">o</span>
        <span className="text-animate">j</span>
        <span className="text-animate">e</span>
        <span className="text-animate">c</span>
        <span className="text-animate">t</span>
        <span className="text-animate">s</span>
        </div>
    


        <section className="project-cards">
      {projects.map(project => (
        <article className="project-card" key={project.id}>
          <div className="project-info">
            <h3 className="project-title">{project.title}</h3>
            <p className="project-description">{project.description}</p>
            <a href={project.githubLink} className="view-code-btn" target="_blank" rel="noopener noreferrer">View Code</a>
          </div>
        </article>
      ))}
    </section>
    </div>
    </div>
  );
};

export default MyProjects;
