// Footer.js

import React from 'react';
import './footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="upper-footer">
        <div className="footer-section">
          <div className="footer-section-item">
            <h4>Address</h4>
            <p>Suryabinyak-04, Bhaktapur<br/> Nepal</p>
          </div>
          <div className="footer-section-item">
            <h4>Email</h4>
            <p>prinsajoshi5@gmail.com</p>
          </div>
          <div className="footer-section-item">
            <h4>Let's Connect</h4>
            <div className="social-icons">




<a href="https://www.facebook.com/prinsajoshi1/"><div class="facebook play-on"></div></a>

<a  href="mailto:joshi.prinsa1234@gmail.com"><div class="mail play-on"></div></a>
<a href="https://www.instagram.com/_prinsa.07/?next=%2F&hl=en"><div class="instagram play-on"></div></a>
<a href="https://www.reddit.com/user/P-Prinsa007/"><div class="reddit play-on"></div></a>
<a href="https://www.linkedin.com/in/prinsa-joshi/"><div class="linkedin play-on"></div></a>
<a href="https://github.com/prinsajoshi"><div class="github play-on"></div></a>
<a href="https://medium.com/@joshi-prinsa1234"><div class="medium play-on"></div></a>
<a href="https://x.com/JoshiPrinsa"><div class="twitter play-on"></div></a>


  {/* Add more social media icons as needed */}


            </div>
          </div>
        </div>
      </div>
      <div className="lower-footer">
        <div className="footer-center">
          <p>© 2024 Prinsa Joshi</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
