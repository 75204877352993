import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './navbarroute.css';
import Navbar from './navbar'; 

import Home from '../Home/Home';
import AboutMe from '../Aboutme/aboutme';
import MyJourney from '../myjourney/myjourney';
import Projects from '../project/project';
import Blogs from '../Blog/blog';
import ContactPage from '../contact/contact';
import Skills from '../skills/skills';

const NavBarRoute = () => {
  return (
    <Router>
      <div className='navbar-align'>
        <Navbar /> 
        <Routes>
          <Route path="home" element={<Home />} />
          <Route path="about-me" element={<AboutMe />} />
          <Route path="my-journey" element={<MyJourney />} />
          <Route path="skills" element={<Skills />} />

          <Route path="projects" element={<Projects />} />
          <Route path="blogs" element={<Blogs />} />
          <Route path="contact" element={<ContactPage />} />

        </Routes>
      </div>
    </Router>
  );
};

export default NavBarRoute;
