import React from 'react';
import { Link } from 'react-scroll'; // Import Link from react-scroll
import './navbarroute.css';

const Navbar = () => {
  return (
    <nav>
      <ul>
      <div className="line2"></div>
        <div className='logo'></div>
        <li><NavLinkWithLineAnimation to="home" label="Home" /></li>
        <li><NavLinkWithLineAnimation to="about-me" label="About me" /></li>
        <li><NavLinkWithLineAnimation to="my-journey" label="My Journey" /></li>
        <li><NavLinkWithLineAnimation to="skills" label="Skills" /></li>

        <li><NavLinkWithLineAnimation to="projects" label="Projects" /></li>
        <li><NavLinkWithLineAnimation to="blogs" label="Blogs" /></li>
        <li><NavLinkWithLineAnimation to="contact" label="Contact" /></li>

      </ul>
    </nav>

  );
};

const NavLinkWithLineAnimation = ({ to, label }) => {
  return (

    <Link
      activeClass="active"
      to={to}
      spy={true}
      smooth={true}
      offset={-120} // Adjust the offset as per your UI
      duration={500} // Animation duration
      className="nav-link"
    >

      {label}
      <div className="line"></div>

    </Link>
  );
};

export default Navbar;
