import React from 'react';
import './skills.css'; // Import CSS file for styling

const Skills = () => {
  return (
    <div>
      <div className="about-container">
        <span className="text-animate">S</span>
        <span className="text-animate">k</span>
        <span className="text-animate">i</span>
        <span className="text-animate">l</span>
        <span className="text-animate">l</span>
        <span className="text-animate">s</span>
      </div>
      <div id="skills">
        <div className="skills-container">
          {/* Second row */}
          <div className="skill skill-skills"></div>
  
        </div>
      </div>
    </div>
  );
};

export default Skills;
