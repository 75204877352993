import React, { useState } from 'react';
import coffeeImage from './images/cofee.gif';
import './contact.css'; // Adjusted import for CSS file

const ContactPage = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const [responseMessage, setResponseMessage] = useState('');

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('http://127.0.0.1:8000/api/contact/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      if (response.ok) {
        setResponseMessage('Message sent successfully!');
        setFormData({ name: '', email: '', message: '' }); // Reset form data
      } else {
        setResponseMessage('Failed to send message.');
      }
    } catch (error) {
      console.error('Error:', error);
      setResponseMessage('An error occurred.');
    }
  };

  return (
    <div id="contact">
      <div className="contact-container">
        <div className="background-image"></div>
        <div className="content-container">
          <div className="text-container">
            <div className="about-container">
              <span className="text-animate-hover_aboutme">C</span>
              <span className="text-animate-hover_aboutme">o</span>
              <span className="text-animate-hover_aboutme">n</span>
              <span className="text-animate-hover_aboutme">t</span>
              <span className="text-animate-hover_aboutme">a</span>
              <span className="text-animate-hover_aboutme">c</span>
              <span className="text-animate-hover_aboutme">t</span>
              <span className="text-animate-hover_aboutme"> </span>
              <span className="text-animate-hover_aboutme">M</span>
              <span className="text-animate-hover_aboutme">e</span>
            </div>
            <form onSubmit={handleSubmit} className="contact-form">
              <input
                type="text"
                name="name"
                placeholder="Enter your name"
                value={formData.name}
                onChange={handleChange}
                className="form-input"
              />
              <input
                type="email"
                name="email"
                placeholder="Enter your email"
                value={formData.email}
                onChange={handleChange}
                className="form-input"
              />
              <textarea
                name="message"
                placeholder="Enter your message"
                value={formData.message}
                onChange={handleChange}
                className="form-textarea"
              ></textarea>
              <button type="submit" className="submit-button">Send</button>
            </form>
            {responseMessage && <p>{responseMessage}</p>}
          </div>
          <div className="image-container">
            <img
              src={coffeeImage}
              alt="Coffee"
              className="coffee-image"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
