import React from 'react';
import './aboutme.css';

const AboutMe = () => {
  return (
    <div id='about-me'>
    <div className="about-container">
      <span className="text-animate-hover_aboutme">A</span>
      <span className="text-animate-hover_aboutme">b</span>
      <span className="text-animate-hover_aboutme">o</span>
      <span className="text-animate-hover_aboutme">u</span>
      <span className="text-animate-hover_aboutme">t</span>
      <span className="space"></span>
      <span className="text-animate-hover_aboutme">M</span>
      <span className="text-animate-hover_aboutme">e</span>

      <div className="about-description" >
      <p>Hi, I am Prinsa, a recent Computer Engineering graduate from Tribhuvan University, 
        IOE-Thapathali Campus. I'm a <strong style={{ fontSize: '23px', color: 'rgb(57, 59, 62)' }}>Python developer</strong> with a keen interest in machine learning. 
        I enjoy exploring new skills, driven by my creative spirit and curiosity.<br/><br />When
         I'm not coding or immersed in academics, you'll often find me reading novels, listening 
         to music, or enjoying the tranquility of nature. I also enjoy watching movies and series. 
         Feel free to reach out to talk about music, movies, or doing projects<span className='star'></span></p>

      </div>
      <a href="https://drive.google.com/file/d/1lnS-YT1Ptj76P8MSzdwDhrJ8aYlKgrji/view?usp=sharing" download="prinsacv.pdf" target="_blank" >  <button className='resume'></button>
</a>
    </div>
    </div>
  );
};

export default AboutMe;
