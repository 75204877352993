import React from "react";
import "./blog.css";
import img1 from './images/bts.jpg';
import img2 from './images/pohar.png';
import img3 from './images/persona.png';
import img4 from './images/docker.png';
import img5 from './images/django.png';
import img6 from './images/django1.png';

const Blog = () => {
  const blogs = [
    {
      id: 1,
      title: 'Their adversity tale',
      description: 'A story about overcoming challenges.',
      img: img1,
      link: 'https://joshi-prinsa1234.medium.com/their-adversity-tale-dd9d1ee5c59f'
    },
    {
      id: 2,
      title: 'Pohar Saal',
      description: 'A beautiful piece of music by Aruna Lama.',
      img: img2,
      link: 'https://joshi-prinsa1234.medium.com/pohar-saal-2070bf0cbcbc'
    },
    {
      id: 3,
      title: 'Persona',
      description: 'An introspective piece about self-reflection.',
      img: img3,
      link: 'https://joshi-prinsa1234.medium.com/persona-d3d521ae7f90'
    },
    {
      id: 4,
      title: 'Docker In One Shot',
      description: 'Allows developers to automate the deployment',
      img: img4,
      link: 'https://joshi-prinsa1234.medium.com/docker-in-one-shot-c67f6af48a33'
    },
    {
      id: 5,
      title: 'Gunicorn and Nginz',
      description: 'Django Application using Gunicorn & Nginx in Production',
      img: img5,
      link: 'https://joshi-prinsa1234.medium.com/django-application-using-gunicorn-nginx-in-production-7b4c3d7536b6'
    },
    {
      id: 6,
      title: 'Django Debug Toolbar',
      description: ' Powerful and popular tool used by Django developers to debug and optimize ',
      img: img6,
      link: 'https://joshi-prinsa1234.medium.com/django-debug-toolbar-9b85e3eb85ed'
    }
  ];
  return (
    <>
    <div id="blogs">
      <div className="about-container">
        <span className="text-animate-hover_aboutme blog">M</span>
        <span className="text-animate-hover_aboutme blog">y</span>
        <span className="space"></span>

        <span className="text-animate-hover_aboutme blog">B</span>
        <span className="text-animate-hover_aboutme blog">l</span>
        <span className="text-animate-hover_aboutme blog">o</span>
        <span className="text-animate-hover_aboutme blog">g</span>
        <span className="text-animate-hover_aboutme blog">s</span>
        

        <section className="cards">
      {blogs.map(blog => (
        <article className="card" key={blog.id}>
          <a href={blog.link} className="card-link">
            <div className="card-img" style={{ backgroundImage: `url(${blog.img})` }}></div>
          </a>
          <div className="card-info">
            <h3 className="card-title">{blog.title}</h3>
            <p className="card-description">{blog.description}</p>
          </div>
        </article>
      ))}
    </section>
      </div>
      </div>
    </>
  );
};

export default Blog;
